<template>
  <SparkModal dismissable :model-value="show" @update:model-value="closeModal">
    <template #custom>
      <div class="text-19 font-bold p-20 border-b border-gray-100">Add Custom Material</div>
      <CustomMaterialForm :show="show" :material-data="updatedFormData" @close="closeModal" @add="addCustomMaterial" />
    </template>
  </SparkModal>
</template>

<script>
import CustomMaterialForm from '../components/CustomMaterialForm.vue';

import SparkModal from '@/components/SparkComponents/SparkModal.vue';

export default {
  name: 'AddCustomMaterialModal',

  components: { CustomMaterialForm, SparkModal },

  props: {
    show: { type: Boolean, required: true },
    materialData: { type: Object, required: true },
    materialDataForm: { type: Object, required: true },
  },

  emits: ['close', 'add'],

  data() {
    return {
      updatedFormData: {},
    };
  },

  watch: {
    materialData: {
      handler(newVal) {
        if (newVal) {
          const updatedForm = this.populateFormValues(newVal, this.materialDataForm);
          this.updatedFormData = updatedForm;
        }
      },

      immediate: true,
      deep: true,
    },
  },

  methods: {
    closeModal() {
      this.$emit('close');
    },

    populateFormValues(materialData, addMaterialForm) {
      function getValueFromMaterialData(materialData, db_name) {
        const keys = db_name.split('.');
        let value = materialData;
        for (let key of keys) {
          value = value ? value[key] : null;
          if (value === null || value === undefined) break;
        }
        return value;
      }

      function assignValues(formArray, data) {
        formArray.forEach(formField => {
          if (formField.db_name) {
            let value;

            if (data.general && data.general[formField.db_name] !== undefined) {
              value = data.general[formField.db_name];
            } else {
              value = getValueFromMaterialData(data, formField.db_name);
            }

            if (value !== undefined && value !== null) {
              formField.value = value;
            }
          }
        });
      }

      for (const section in addMaterialForm) {
        if (Array.isArray(addMaterialForm[section])) {
          assignValues(addMaterialForm[section], materialData);
        }
      }

      return addMaterialForm;
    },

    addCustomMaterial(data) {
      this.$emit('add', data);
      this.closeModal();
    },
  },
};
</script>
