<template>
  <div class="flex w-40 m-8 hover:bg-gray-50 p-12 items-center justify-center rounded-8">
    <img v-if="creationInProgress" src="@/assets/img/loading.gif" />
    <i v-else-if="creationFinished" class="fas fa-check" />
    <i v-else class="fas fa-plus" />
  </div>
</template>

<script>
export default {
  name: 'AnimationSuggestion',

  props: {
    creationInProgress: Boolean,
    creationFinished: Boolean,
  },
};
</script>
