<template>
  <SparkModal custom="h-[80%]" :model-value="show" @update:model-value="close">
    <template #default>
      <div class="text-19 font-semibold" v-text="'Add Condition and Expression'" />
      <div class="text-13 text-primary-500 hover:text-primary-700">
        <a
          href="https://25363036.hs-sites-eu1.com/3d-spark-knowledge-base/pricing-configurator"
          target="_blank"
          rel="noopener noreferrer"
          >Need help?</a
        >
      </div>
      <div>
        <div class="flex flex-col gap-16">
          <div v-if="showCondition || selectedPricing?.rule" class="mt-20">
            <div class="text-gray-400" v-text="'Condition'" />
            <CustomExpression
              :incoming-words="pricing?.ruleWords"
              :incoming-actual-value="pricing?.rule"
              label="Condition"
              :reset="reset"
              :options="ruleOptions"
              @input="setRule"
            />
            <div class="text-gray-400 text-11" v-text="'Leave empty if expression below applies in all cases'" />
          </div>

          <div>
            <div class="flex justify-between">
              <div v-if="!showCustomTextInput" class="text-gray-400" v-text="'Expression'" />
              <div v-else />
              <div
                class="cursor-pointer text-primary-500"
                @click="toggleCustomTextInput"
                v-text="showCustomTextInput ? 'Use expresson' : 'Text instead of price'"
              />
            </div>
            <CustomExpression
              v-if="!showCustomTextInput"
              :reset="reset"
              :incoming-actual-value="pricing?.expression"
              :incoming-words="pricing?.expressionWords"
              label="Expression"
              :options="expressionOptions"
              @input="setExpression"
            />
          </div>

          <SparkInput
            v-if="showCustomTextInput"
            v-model="customText"
            name="customText"
            label="Custom Text"
            top-label
            placeholder="Enter custom text"
            @change="setCustomText"
          />
        </div>

        <div class="flex justify-end gap-16 mt-100">
          <SparkButton variant="outlined" @click="close">
            <div v-text="'Cancel'" />
          </SparkButton>
          <SparkButton
            :disabled="!expression && !customText"
            variant="secondary"
            :title="!expression && !customText ? 'Please add an expression or a custom text' : ''"
            @click="handleConfirm"
          >
            <div v-text="mode === 'add' ? 'Add Rule' : 'Update Rule'" />
          </SparkButton>
        </div>
      </div>
    </template>
  </SparkModal>
</template>

<script>
import { mapState } from 'vuex';

import CustomExpression from '@/components/General/CustomExpression.vue';
import SparkButton from '@/components/SparkComponents/SparkButton.vue';
import SparkInput from '@/components/SparkComponents/SparkInput.vue';
import SparkModal from '@/components/SparkComponents/SparkModal.vue';

export default {
  name: 'AddExpressionRuleModal',

  components: { CustomExpression, SparkModal, SparkButton, SparkInput },

  props: {
    show: { type: Boolean, required: true },
    selectedPricing: { type: Object, default: null },
    mode: { type: String, default: 'add' },
    pricingList: { type: Array, default: () => [] },
  },

  emits: ['close', 'save', 'update'],

  data() {
    return {
      isMounted: false,
      expression: '',
      rule: '',
      customText: '',
      pricing: null,
      showCustomTextInput: false,
      showCondition: false,

      reset: 0,
    };
  },

  computed: {
    ...mapState(['expressionOptions', 'ruleOptions']),
  },

  watch: {
    show: {
      handler(value) {
        if (value && this.selectedPricing && this.mode === 'edit') {
          this.reset++;
          this.expression = this.selectedPricing?.expression || '';
          this.rule = this.selectedPricing?.rule || '';
          this.customText = this.selectedPricing?.custom_text || '';
          this.pricing = this.selectedPricing;
        }

        if (value && this.pricingList.length > 0) {
          let hasEmptyRule = false;
          this.pricingList.forEach(pricing => {
            if (!pricing.rule || pricing.rule === '') {
              hasEmptyRule = true;
            }
          });
          this.showCondition = hasEmptyRule;
        }
      },

      immediate: true,
    },
  },

  mounted() {
    this.isMounted = true;
  },

  methods: {
    setExpression(value) {
      this.expression = value;
    },

    setRule(value) {
      this.rule = value;
    },

    setCustomText(value) {
      this.customText = value.target.value;
    },

    close() {
      this.customText = '';
      this.reset++;
      this.pricing = null;
      this.$emit('close');
    },

    save() {
      this.$emit('save', { expression: this.expression, rule: this.rule, customText: this.customText });
      this.pricing = null;
      this.$emit('close');
    },

    update() {
      this.$emit('update', {
        expression: this.expression,
        rule: this.rule,
        customText: this.customText,
        index: this.selectedPricing?.order_index,
      });
      this.pricing = null;
      this.$emit('close');
    },

    handleConfirm() {
      if (this.mode === 'add') {
        this.save();
      } else {
        this.update();
      }
    },

    toggleCustomTextInput() {
      this.showCustomTextInput = !this.showCustomTextInput;
    },
  },
};
</script>
