<template>
  <div>
    <teleport v-if="isMounted" to="#sub-navigation__action-buttons">
      <div class="flex flex-col">
        <SparkInput
          v-model="searchQuery"
          custom="pb-0 mb-0"
          name="filter"
          placeholder="Search templates..."
          class="w-200"
          @input="filterTemplates"
        />
        <div class="text-11 pt-0 text-right text-gray-600 hover:text-gray-900 cursor-pointer" @click="clearFilter">
          Clear
        </div>
      </div>
    </teleport>
    <table v-if="!loading" class="bg-white rounded-8 w-full text-13">
      <thead class="border-b border-gray-50 h-56 text-gray-400 font-normal">
        <th class="px-8 w-1/6">Name</th>
        <th class="px-8 w-1/6">Owner</th>
        <th class="px-8 w-1/6">Restriction</th>
        <th class="px-8 w-1/6">Process</th>
        <th class="px-8 w-1/6">Machine</th>
        <th class="px-8 w-1/6">Material</th>
      </thead>
      <tbody>
        <tr v-for="template in filteredTemplates" :key="template.uid" class="h-56 border-b border-gray-50">
          <td class="px-8 w-1/6">{{ template.name }}</td>
          <td class="px-8 w-1/6">{{ template.owner_username }}</td>
          <td class="px-8 w-1/6">{{ template.restriction }}</td>
          <td class="px-8 w-1/6">{{ template.prc_name }}</td>
          <td class="px-8 w-1/6">{{ template.machine }}</td>
          <td class="px-8 w-1/6">{{ template.material_name }}</td>
        </tr>
      </tbody>
    </table>
    <div v-else class="flex justify-center items-center h-full">
      <img class="w-24" src="@/assets/img/loading.gif" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import SparkInput from '@/components/SparkComponents/SparkInput.vue';

export default {
  name: 'ProcessChainTemplateLibrary',

  components: {
    SparkInput,
  },

  data() {
    return {
      templates: [],
      filteredTemplates: [],
      isMounted: false,
      searchQuery: '',
      loading: true,
    };
  },

  computed: {
    ...mapState('application', ['axiosInstance']),
  },

  created() {
    this.fetchTemplates();
  },

  mounted() {
    this.isMounted = true;
  },

  methods: {
    async fetchTemplates() {
      this.loading = true;
      try {
        const response = await this.axiosInstance.get('api/v1/process-chain-template-list/');
        this.templates = response.data;
        this.filteredTemplates = this.templates;
        this.loading = false;
      } catch (error) {
        this.$root.notify('error', 'Failed to fetch templates', '', 5000);
      } finally {
        this.loading = false;
      }
    },

    filterTemplates() {
      // Filter templates based on search query matching any field
      if (!this.templates) return [];
      // Convert object to array of values and filter
      this.filteredTemplates = Object.values(this.templates).filter(template => {
        const searchLower = this.searchQuery.toLowerCase();
        return (
          template.name?.toLowerCase().includes(searchLower) ||
          template.prc_name?.toLowerCase().includes(searchLower) ||
          template.owner_username?.toLowerCase().includes(searchLower) ||
          template.machine?.toLowerCase().includes(searchLower) ||
          template.material_name?.toLowerCase().includes(searchLower)
        );
      });
    },

    clearFilter() {
      this.searchQuery = '';
      this.filteredTemplates = this.templates;
    },
  },
};
</script>
