<template>
  <div class="bg-gray-50 p-8 rounded-4 m-8">
    <div v-if="Object.keys(externalMarketPrices).length != 0">
      <div v-for="price in externalMarketPrices" :key="price" class="flex justify-between h-32">
        <div class="text-gray-500 text-11">{{ price.name }} | {{ price.prc }}</div>
        <div class="text-right font-bold text-black mr-32 text-11">
          {{ $formatTwoDecimalPlaces(price.price) }} {{ price.unit }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'ExternalMarketPrice',

  computed: {
    ...mapState({ storePart: 'part' }),
    ...mapState(['actualMarketPriceRequest']),
    ...mapState('application', ['axiosInstance']),

    externalMarketPrices() {
      return this.part.market_prices_api;
    },

    part: {
      get() {
        return this.storePart;
      },

      set(value) {
        this.updatePart(value);
      },
    },
  },

  mounted() {
    if (Object.keys(this.externalMarketPrices).length == 0) {
      this.requestMarketPrice();
    }
  },

  methods: {
    ...mapMutations(['updatePart', 'updateExternalMarketPrice']),

    async requestMarketPrice() {
      this.axiosInstance
        .get(`/api/v1/market-price/${this.storePart.part_id}`)
        .then(response => {
          this.updateExternalMarketPrice(response.data);
        })
        .catch(error => {
          console.log(error);
        });
    },
  },
};
</script>
