<template>
  <div
    class="flex items-center py-4"
    @mouseenter="mouseHover = true"
    @mouseleave="mouseHover = false"
    @click="selected"
  >
    <AnimationSuggestion
      :creation-in-progress="creationInProgress"
      :creation-finished="creationFinished"
      :mouse-hover="mouseHover"
    />
    <div class="flex flex-col w-1/6 mt-2">
      <div v-if="profile.uid == defaultAnalysisProfile" class="text-11 text-orange-500">Default</div>
      <div class="font-bold">{{ profile.name }}</div>
      <div class="text-gray-400 text-11 font-normal">{{ numberOfTemplates }} Options</div>
    </div>
    <div class="w-1/6">{{ profile.restriction }}</div>
    <details class="flex text-11 cursor-pointer" title="Click to show" @click.stop>
      <summary class="text-1 text-gray-600 hover:text-gray-800">Show</summary>
      <draggable :list="templateList" item-key="order_index" @end="reindexProfileTemplates">
        <template #item="{ element }">
          <div class="draggable-item flex items-center">
            <div class="drag-icon mx-10">&#x2630;</div>
            <TemplateDetails :template="element" />
            <SparkButton variant="outlined" small class="delete-button ml-12" @click="deleteFromProfile(element)">
              <div class="fas fa-trash text-11" />
            </SparkButton>
          </div>
        </template>
      </draggable>
    </details>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { mapMutations, mapState } from 'vuex';

import SparkButton from '../../../SparkComponents/SparkButton.vue';

import AnimationSuggestion from './AnimationSuggestion.vue';
import TemplateDetails from './TemplateDetails.vue';

import Badge from '@/components/Reusable/Badge.vue';
export default {
  name: 'ProfileRow',

  components: { Badge, AnimationSuggestion, TemplateDetails, draggable, SparkButton },

  props: {
    profile: { type: Object, default: () => ({}) },

    loadingUid: { type: String, default: '' },
  },

  emits: ['selected-profile'],

  data() {
    return {
      mouseHover: false,
      creationInProgress: false,
      creationFinished: false,
      selectable: true,
    };
  },

  computed: {
    ...mapState(['user', 'part']),
    ...mapState('application', ['axiosInstance']),
    defaultAnalysisProfile() {
      return this.user.default_analysis_profile;
    },

    numberOfTemplates() {
      return Object.keys(this.profile.process_chain_templates).length;
    },

    templateList() {
      let templates = Object.values(this.profile.process_chain_templates);
      return templates.sort((a, b) => a.order_index - b.order_index);
    },

    cadUploadedOrCadless() {
      return this.part.part_id != 0 && (this.part.basename != '' || this.part.is_cadless);
    },
  },

  watch: {
    loadingUid(newVal, oldVal) {
      if (oldVal == this.profile.uid && newVal == '') {
        this.creationInProgress = false;
        this.creationFinished = true;
        setTimeout(() => {
          this.creationFinished = false;
        }, 3000);
      } else if (newVal == 'abort') {
        this.creationInProgress = false;
        this.selectable = true;
      }
    },
  },

  methods: {
    ...mapMutations(['updateTemplatesAndProfiles']),
    selected() {
      if (this.selectable) {
        this.$emit('selected-profile');
        if (this.cadUploadedOrCadless) {
          this.creationInProgress = true;
        }
      }
      this.selectable = false;
      setTimeout(() => {
        this.selectable = true;
      }, 3000);
    },

    reindexProfileTemplates() {
      let formData = {
        template_order: {},
      };
      this.templateList.forEach((template, index) => {
        template.order_index = index;
        formData.template_order[index] = template.uid;
      });

      // put new order
      this.axiosInstance.put(`/api/v1/analysis-profile/${this.profile.uid}/`, formData).then(response => {
        console.debug(response.data); // Updated profile
      });
    },

    deleteFromProfile(element) {
      this.axiosInstance.delete(`api/v1/analysis-profile-list/${element.uid}/`).then(() => {
        this.updateTemplatesAndProfiles(new Date());
      });
    },
  },
};
</script>
