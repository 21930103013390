<template>
  <div>
    <div class="bg-white w-full">
      <template v-for="(section, sectionName) in materialData" :key="sectionName">
        <CollapsibleSection :title="sectionName">
          <template v-for="field in section" :key="field.db_name">
            <Component
              :is="field.component"
              v-model="formData[field.db_name]"
              :name="field.db_name"
              :label="field.name"
              :options="Array.isArray(field.options) && field.options.length > 0 ? field.options : []"
              :type="field.field"
              top-label
            />
          </template>
        </CollapsibleSection>
      </template>
    </div>

    <div class="flex justify-end m-20 gap-8">
      <SparkButton variant="outlined" @click="$emit('close')">
        <div v-text="'Cancel'" />
      </SparkButton>
      <SparkButton variant="secondary" type="button" @click="handleSubmit">
        <div v-text="'Add Material'" />
      </SparkButton>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import CollapsibleSection from './CollapsibleSection.vue';

import SparkButton from '@/components/SparkComponents/SparkButton.vue';
import SparkForm from '@/components/SparkComponents/SparkForm.vue';
import SparkInput from '@/components/SparkComponents/SparkInput.vue';
import SparkSelect from '@/components/SparkComponents/SparkSelect.vue';

export default {
  name: 'CustomMaterialForm',

  components: {
    CollapsibleSection,
    SparkButton,
    SparkForm,
    SparkInput,
    SparkSelect,
  },

  props: {
    materialData: { type: Object, required: true },
    show: { type: Boolean, required: true },
  },

  emits: ['add', 'close'],

  data() {
    return {
      formData: {},
    };
  },

  computed: {
    ...mapState([
      'curTechs',
      'filteredMachines',
      'filteredMachines',
      'materialLevel2',
      'en45545Requirements',
      'hazardLevels',
      'ul94Flammabilities',
      'nfpa130Compliancies',
      'user',
    ]),
  },

  watch: {
    show: {
      handler(newShow) {
        if (newShow && Object.keys(this.materialData).length > 0) {
          this.formData = {};
          Object.values(this.materialData).forEach(section => {
            section.forEach(field => {
              this.formData[field.db_name] = field.value;
            });
          });
        }
      },

      immediate: true,
    },
  },

  methods: {
    handleSubmit() {
      this.$emit('add', this.formData);
    },
  },
};
</script>
