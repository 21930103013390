<template>
  <SparkModalBase
    v-model="value"
    :dismissable="dismissable"
    :class="{ [custom]: !!custom }"
    class="py-safe m-0 max-h-screen w-full max-w-[47.75rem] bg-transparent sm:m-auto sm:p-32"
  >
    <div class="text-13 sm:rounded-8 max-h-[80vh] overflow-auto bg-white text-black">
      <slot name="custom">
        <div class="relative flex h-full flex-col">
          <div
            v-if="$slots.header"
            class="text-17 flex h-64 items-center w-full justify-between border-b border-gray-50 font-semibold p-20 sticky top-0 bg-white z-10"
          >
            <slot name="header" />
            <SparkButtonIcon v-if="dismissable" class="!outline-none" icon="fas fa-xmark" @click="closeModal" />
          </div>
          <div
            class="scrollbar-thin scrollbar-thumb-black/50 scrollbar-thumb-rounded-full flex-grow overflow-y-auto overflow-x-hidden m-20"
          >
            <slot />
          </div>
          <div v-if="$slots.footer" class="sticky bottom-0 bg-white border-t border-gray-50">
            <div class="p-20 pt-10">
              <slot name="footer" />
            </div>
          </div>
        </div>
      </slot>
    </div>
  </SparkModalBase>
</template>

<script setup>
import { computed } from 'vue';

import SparkButtonIcon from './SparkButtonIcon.vue';
import SparkModalBase from './SparkModalBase.vue';

const props = defineProps({
  modelValue: Boolean,
  dismissable: Boolean,
  custom: { type: String, default: '' },
  compactContent: Boolean,
});

const emits = defineEmits(['update:modelValue', 'close']);

const closeModal = () => {
  emits('update:modelValue', false);
  emits('close');
  value.value = false;
};

const value = computed({
  get: () => props.modelValue,
  set: value => emits('update:modelValue', value),
});
</script>
