import { Store } from 'vuex';

import applicationModule from './application';
import calibrationModule from './calibration';
import canvasModule from './canvas';
import prpModule from './prp';
import supplierModule from './supplier';

import defaultImage from '@/assets/img/Logo_Transparent.png';
import useCopyObject from '@/composables/copyObject.js';
import { checkAllProcessChainStatus, checkAnyProcessChainStatus, getDayAndMonthFromDate } from '@/helpers.js';

function calculatePercentageOfGoal(startDate, endDate) {
  const currentDate = new Date();
  if (currentDate < startDate) {
    return 0;
  } else if (currentDate > endDate) {
    return 100;
  } else {
    const totalDays = (endDate - startDate) / (1000 * 60 * 60 * 24);
    const daysPassed = (currentDate - startDate) / (1000 * 60 * 60 * 24);
    const percentage = (daysPassed / totalDays) * 100;

    return Math.floor(percentage > 100 ? 100 : percentage);
  }
}

function getGoalBackgroundColor(goalToday, achievedGoal) {
  if (achievedGoal < goalToday) return '#F9B124';
  if (achievedGoal === goalToday) return '#157905';
  if (achievedGoal > goalToday) return '#23C209';
  return '';
}

const store = new Store({
  state: {
    /* User & Authentication */
    isAuthenticated: false, // Validate if user is logged in
    token: '', // Token from backend
    refreshToken: '', // refresh-token from backend
    user: {
      // User info fetched on login
      is_staff: false,
      organization: {
        color: {
          primary_color: null,
          primary_text_color: null,
          accent_color: null,
          accent_text_color: null,
        },
        additional_cost_types: {},
      },
      profile_pic: '',
    },
    roles: [],
    organizationGoal: [],
    organizationGoalDashboardDetails: [],

    reloadTemplatesAndProfiles: '',

    organization: {},

    /* Part Workflow */
    sidebar_collapsed: false,
    actualMarketPriceRequest: '',
    allMachines: {},
    batches: {},
    filteredMachines: {},
    partStats: {},
    curTechs: {},
    assetTypes: {},
    popup: {},
    unsavedChanges: [], // When impossible to save to backend unsaved changes are stored here
    investigationDetails: '',
    possibleOptimizationStyles: {
      custom: 'Custom',
      alpha_beta_gamma: 'Full',
      gamma_only: 'Z-Only',
      flat: 'Flat',
      fixed: 'Off',
    },
    empty_process_chain: {
      0: {
        name: 'New process',
        prc: 'Default Process',
        material_name: 'Default Material',
        machine_name: 'Default Machine',
        lot_size: 1,
        feasibility: undefined,
        process_chain_id: '0',
        cost: { cost_pp: undefined, cost_pl: undefined, cost_pj: undefined },
        leadtime: undefined,
        co2: {
          co2_pp: undefined,
          co2_pl: undefined,
          co2_pj: undefined,
          co2_ec_pp: undefined,
          co2_ec_pl: undefined,
          co2_ec_pj: undefined,
        },
      },
    },
    // The currently active part, updated by user inputs and backend tasks
    part: {
      part_id: '',
      name: '',
      part_vol: null,
      current_part_mass: null,
      basename: '',
      mat_id: null,
      bb_x: null,
      bb_y: null,
      bb_z: null,
      lot_size: 1,
      current_mat: '',
      current_mat_analysis: {},
      info: '',
      upload_file_units: 'mm',
      assets: {},
      part_stat: 'unset', // available states: '-', 'Validated', 'Not Validated'
      process_chains: {},
      categories: {
        text_categories: {},
        combo_categories: {},
      },
      is_assembly: false,
      is_cadless: false,
      market_prices_api: {},
      drawing_analysis: {},
      mat_name: '',
      pdf_requested: false,
      displayInfo: '',
    },

    //Pricing
    expressionOptions: [
      { variable: '-', verbose: '-', unit: '-', category: 'operator' },
      { variable: '+', verbose: '+', unit: '-', category: 'operator' },
      { variable: '*', verbose: '*', unit: '-', category: 'operator' },
      { variable: '/', verbose: '/', unit: '-', category: 'operator' },
      { variable: '(', verbose: '(', unit: '-', category: 'operator' },
      { variable: ')', verbose: ')', unit: '-', category: 'operator' },
    ],

    ruleOptions: [
      { variable: '+', verbose: '+', unit: '-', category: 'operator' },
      { variable: '-', verbose: '-', unit: '-', category: 'operator' },
      { variable: '*', verbose: '*', unit: '-', category: 'operator' },
      { variable: '/', verbose: '/', unit: '-', category: 'operator' },
      { variable: '(', verbose: '(', unit: '-', category: 'operator' },
      { variable: ')', verbose: ')', unit: '-', category: 'operator' },
      { variable: '>', verbose: '>', unit: '-', category: 'operator' },
      { variable: '<', verbose: '<', unit: '-', category: 'operator' },
      { variable: '>=', verbose: '>=', unit: '-', category: 'operator' },
      { variable: '<=', verbose: '<=', unit: '-', category: 'operator' },
      { variable: '==', verbose: '==', unit: '-', category: 'operator' },
      { variable: '!=', verbose: '!=', unit: '-', category: 'operator' },
    ],

    /* Part Library */
    partLibraryFilters: { combo_categories: {}, text_categories: {}, part_stat: 'all' },
    possiblePartLibraryFilters: { enabled: [], disabled: [] },
    partLibraryData: {},
    activeId: '', // The part id of the active part (e.g. the selected one in the part library)
    reloadPartLibrary: false,
    parts: [],
    prpSettingsProperties: {
      partProperties: {
        part_volume: { label: 'Part Volume', value: true },
        bounding_box: { label: 'Bounding Box', value: true },
        part_mass: { label: 'Part Mass', value: true },
        support_surface: { label: 'Support Surface', value: true },
        bb_x: { label: 'Bounding Box X', value: true },
        bb_y: { label: 'Bounding Box Y', value: true },
      },
      processChainProperties: { parts_per_job: { label: 'Parts per Job', value: true } },
    },

    /* Batch */
    activeBatch: null,
    activeContent: 'Files',

    /* File Export */
    fetchOptimalOrientation: false,
    fetchPartLibraryDump: false,
    fetchAnalysisResultsXLS: false,
    fetchCustomReportDOCX: '',
    fetchCustomListReportDOCX: false,

    /* Mode Togglers */

    /* Dashboard */
    dashboardFilters: {
      part_stat: 'validated',
      query_type: 'organization',
      combo_categories: {},
      text_categories: {},
    },

    /* User Notifications */
    userNotifications: {},
    partUploadError: '',

    /* Initialization State of Product Fruits (Tour) */
    productFruitsInitialized: false,
    triggerRFQ: 0,
  },
  // Mutations are always synchronous
  mutations: {
    setAxiosInstance(state, axiosInstance) {
      state.axiosInstance = axiosInstance;
    },
    setAuthenticated(state, authenticated) {
      state.isAuthenticated = authenticated;
    },

    setBatches(state, batches) {
      state.batches = batches;
    },

    setPricingOptions(state, data) {
      state.pricingOptions = data;
    },

    setAnalysisProfiles(state, data) {
      state.analysisProfiles = data;
    },

    setAnalysisTemplates(state, data) {
      state.analysisTemplates = data;
    },

    setAnalysisTemplatesAndProfiles(state, data) {
      state.analysisTemplatesAndProfiles = data;
    },

    setPartUploadError(state, error) {
      state.partUploadError = error;
    },

    setPrpOrderCategories(state, categories) {
      state.prpOrderCategories = categories;
    },

    setProcessChainUpdated(state, value) {
      state.processChainUpdated = value;
    },

    setRefreshToken(state, refreshToken) {
      state.refreshToken = refreshToken;
    },
    removeTokens(state) {
      state.isAuthenticated = false;
    },
    setUser(state, user) {
      state.user = user;
    },
    removeUser(state) {
      localStorage.removeItem('user');
      state.user = {};
    },
    updateUserNotifications(state, notification) {
      state.userNotifications = {
        ...state.userNotifications,
        [notification.timestamp]: notification,
      };
    },
    setParts(state, parts) {
      state.parts = parts;
    },

    deleteUserNotification(state, notificationKey) {
      delete state.userNotifications[notificationKey];
    },
    updateColor(state, colorObj) {
      if (state.user.organization.color == undefined) {
        state.user.organization.color = {};
      }
      if (colorObj.canvas === 'primary') {
        state.user.organization.color.primary_color = colorObj.color;
        state.user.organization.color.primary_text_color = colorObj.textcolor;
      } else if (colorObj.canvas === 'accent') {
        state.user.organization.color.accent_color = colorObj.color;
        state.user.organization.color.accent_text_color = colorObj.textcolor;
      }
    },
    updateColorObject(state, colorObj) {
      state.user.organization.color = colorObj;
    },
    setProfilePic(state, data) {
      state.user.profile_pic = data;
    },
    updateActiveId(state, newId) {
      state.activeId = newId;
    },
    setReloadPartLibrary(state, reload) {
      state.reloadPartLibrary = reload;
    },
    updateActiveBatch(state, newEl) {
      state.activeBatch = newEl;
    },
    setProcessChainExpressionVariables(state, data) {
      state.ruleOptions = [...data, ...state.ruleOptions];
      state.expressionOptions = [...data, ...state.expressionOptions];
    },
    updateActiveProcess(state, newEl) {
      state.activeProcess = newEl;
    },
    updatePart(state, data) {
      state.part = { ...state.part, ...data }; // Merge current part with incoming data (overwrite if necessary)
    },
    updateFetchOptimalOrientation(state, newBool) {
      state.fetchOptimalOrientation = newBool;
    },
    updateFetchPartLibraryDump(state, newBool) {
      state.fetchPartLibraryDump = newBool;
    },
    updateFetchAnalysisResultsXLS(state, newBool) {
      state.fetchAnalysisResultsXLS = newBool;
    },
    updateFetchCustomReportDOCX(state, newPayload) {
      state.fetchCustomReportDOCX = newPayload;
    },
    updateFetchCustomListReportDOCX(state, newBool) {
      state.fetchCustomListReportDOCX = newBool;
    },
    updatedPartLibraryData(state, data) {
      state.partLibraryData = data;
    },
    highlightCategories(state, data) {
      state.part.highlightCategories = data;
    },
    resetPart(state) {
      (state.investigationDetails = ''), (state.part = {});
      state.part = {
        part_id: '',
        name: '',
        part_vol: null,
        current_part_mass: null,
        basename: '',
        mat_id: null,
        bb_x: null,
        bb_y: null,
        bb_z: null,
        lot_size: 1,
        cad_stat: null,
        cad_upload_progress: null,
        current_mat: '',
        current_mat_analysis: {},
        info: '',
        upload_file_units: 'mm',
        fits_mac: true,
        size_min_chk: true,
        size_max_chk: true,
        assets: {},
        technicalDrawing: '',
        part_stat: 'unset',
        process_chains: {},
        categories: {
          text_categories: {},
          combo_categories: {},
        },
        is_assembly: false,
        is_cadless: false,
        market_prices_api: {},
        mesh_checks: {},
        drawing_analysis: {},
        pdf_requested: false,
        displayInfo: '',
        thumbnail: '',
      };
    },
    setProcessSynonyms(state, data) {
      state.user.organization.process_synonyms = data;
    },
    updateUnsavedChanges(state, formData) {
      /* store unsaved changes of the part in the store
      before part frame route is left the changes can be saved or 
      the user can be informed if a save is currently not possible.*/

      // possible to clean unsavedChanges, if they were saved or should be forgotten
      if (formData == null) {
        state.unsavedChanges = [];
        return;
      }

      if (state.unsavedChanges.combo_categories != undefined && formData.combo_categories != undefined) {
        state.unsavedChanges.combo_categories = {
          ...state.unsavedChanges.combo_categories,
          ...formData.combo_categories,
        };
      } else if (state.unsavedChanges.text_categories != undefined && formData.text_categories != undefined) {
        state.unsavedChanges.text_categories = {
          ...state.unsavedChanges.text_categories,
          ...formData.text_categories,
        };
      } else {
        state.unsavedChanges = { ...state.unsavedChanges, ...formData };
      }
    },
    triggerPopup(state, data) {
      if (data != '') {
        state.popup = { ...state.popup, ...data };
      } else {
        state.popup = {};
      }
    },
    setIsCadless(state, data) {
      state.part.is_cadless = data;
    },
    setPartCategories(state) {
      if (state.user != undefined) {
        if (state.user.organization != undefined) {
          if (state.user.organization.text_categories != undefined) {
            // let text_cat_clone = structuredClone(state.user.organization.text_categories);
            let text_cat_clone = useCopyObject(state.user.organization.text_categories);
            for (const [key, value] of Object.entries(text_cat_clone)) {
              if (!state.part.categories.text_categories.hasOwnProperty(key)) {
                state.part.categories.text_categories[key] = value;
                // this is because of the naming of model fields to have 100% the name of the category
                state.part.categories.text_categories[key].category = value.label;
              }
            }
          }
          // ComboCategories
          if (state.user.organization.combo_categories != undefined) {
            // let combo_cat_clone = structuredClone(state.user.organization.combo_categories);
            let combo_cat_clone = useCopyObject(state.user.organization.combo_categories);
            for (const [key, value] of Object.entries(combo_cat_clone)) {
              if (!state.part.categories.combo_categories.hasOwnProperty(key)) {
                state.part.categories.combo_categories[key] = value;
                // this is because of the naming of model fields to have 100% the name of the category
                state.part.categories.combo_categories[key].category = value.label;
              }
            }
          }
        }
      }
      // sort alphabetically
      // sort required
    },
    setPrpCategories(state) {
      // PRP Text Categories
      if (state.user.organization.prp_text_categories != undefined) {
        let prp_text_cat_clone = useCopyObject(state.user.organization.prp_text_categories);
        for (const [key, value] of Object.entries(prp_text_cat_clone)) {
          if (!state.user.organization.prp_text_categories.hasOwnProperty(key)) {
            state.user.organization.prp_text_categories[key] = value;
            // this is because of the naming of model fields to have 100% the name of the category
            state.user.organization.prp_text_categories[key].category = value.label;
          }
        }
      }
      // PRP Combo Categories
      if (state.user.organization.prp_combo_categories != undefined) {
        let prp_combo_cat_clone = useCopyObject(state.user.organization.prp_combo_categories);
        for (const [key, value] of Object.entries(prp_combo_cat_clone)) {
          if (!state.user.organization.prp_combo_categories.hasOwnProperty(key)) {
            state.user.organization.prp_combo_categories[key] = value;
            // this is because of the naming of model fields to have 100% the name of the category
            state.user.organization.prp_combo_categories[key].category = value.label;
          }
        }
      }
    },
    updateTextCategories(state, category) {
      for (const [key, value] of Object.entries(category)) {
        state.part.categories.text_categories[key] = value;
      }
    },
    updateComboCategories(state, category) {
      for (const [key, value] of Object.entries(category)) {
        state.part.categories.combo_categories[key].uid = value.uid;
        state.part.categories.combo_categories[key].value = value.value;
      }
    },
    updateSelectedMaterial(state, material) {
      if (material) {
        state.part.mat_id = material.mat_id;
        state.material = material;
        state.part.material_name = material.mat_name;
      } else {
        state.part.mat_id = '';
        state.material = {};
      }
    },
    updatePartStat(state, partStat) {
      if (partStat) {
        state.part.part_stat = partStat;
      } else {
        state.part.part_stat = '';
      }
    },
    rotUserChangedSingleAxis(state, data) {
      let processChainUid = data.uid;
      if (processChainUid != '' && processChainUid != null) {
        let rot_user = state.part.process_chains[processChainUid].rot_user;
        rot_user[data.axis] = data.value;
        rot_user['pushToBackend'] = data.pushToBackend;
      }
    },
    rotUserChangedAllAxes(state, data) {
      let processChainUid = data.uid;
      if (processChainUid != '' && processChainUid != null) {
        let rot_user = state.part.process_chains[processChainUid].rot_user;
        rot_user['x'] = data.x;
        rot_user['y'] = data.y;
        rot_user['z'] = data.z;
        rot_user['pushToBackend'] = data.pushToBackend;
      }
    },
    updateOptimizationStyle(state, data) {
      state.part.process_chains[data.uid].opt_style = data.setting;
    },
    updateCalculationStyle(state, data) {
      state.part.process_chains[data.uid].calc_style = data.setting;
    },
    updateLotOnly(state, value) {
      state.part.process_chains[value.uid].lot_only = value.setting;
    },
    updateNstDimension(state, value) {
      state.part.process_chains[value.uid].nst_dim = value.setting;
    },
    updateActiveContent(state, newContent) {
      state.activeContent = newContent;
    },
    updateRoundedUserLeadtime(state, newNum) {
      state.part.user_leadtime = newNum;
    },
    updateRoundedUserCost(state, newNum) {
      state.part.user_cost = newNum;
    },
    updateMarketPricePerPartUserValue(state, newUserValue) {
      state.part.process_chains[newUserValue.uid].market_price.market_price_pp.user = newUserValue.value;
    },
    updatePartsPj(state, newUserValue) {
      state.part.process_chains[newUserValue.uid].parts_pj = newUserValue.value;
    },
    setChoiceLists(state, newData) {
      state.partStats = { ...state.partStats, ...newData.part_stats };
      state.curTechs = { ...state.curTechs, ...newData.techs };
      state.assetTypes = { ...state.assetTypes, ...newData.asset_types };

      state.processes = { ...state.processes, ...newData.processes };

      // Set process value to 'all_processes' where it is null
      Object.keys(state.processes).forEach(key => {
        if (state.processes[key].value === null) {
          state.processes[key].value = 'all_processes';
        }
      });

      state.materialLevel2 = {
        ...state.materialLevel2,
        ...newData.material_level_2,
      };
      state.materialLevel3 = {
        ...state.materialLevel3,
        ...newData.material_level_3,
      };
      state.materialLevel4 = {
        ...state.materialLevel4,
        ...newData.material_level_4,
      };
      state.en45545Requirements = {
        ...state.en45545Requirements,
        ...newData.en45545_requirements,
      };
      state.hazardLevels = { ...state.hazardLevels, ...newData.hazard_levels };
      state.ul94Flammabilities = {
        ...state.ul94Flammabilities,
        ...newData.ul94_flammabilities,
      };
      state.nfpa130Compliancies = {
        ...state.nfpa130Compliancies,
        ...newData.nfpa130_compliancies,
      };
      state.workerTypes = { ...state.workerTypes, ...newData.worker_types };
      state.calcLvls = { ...state.calcLvls, ...newData.calc_lvls };
      state.userCompanyRoles = { ...state.userCompanyRoles, ...newData.user_company_roles };
    },

    setorganizationGoal(state, goals) {
      state.organizationGoal = goals;
    },

    setorganizationGoalDashboardDetails(state, details) {
      state.organizationGoalDashboardDetails = details;
    },

    setPipelineStatuses(state, statuses) {
      let statiList = Object.values(statuses);
      state.pipeline = statiList.sort((a, b) => a.order_index - b.order_index);
    },

    setPartPipelineStatus(state, name) {
      state.part.pipeline_status_name = name;
    },

    updateCadStat(state, cadStat) {
      state.part.cad_stat = cadStat;
    },
    updateCadUploadProgress(state, progress) {
      state.part.cad_upload_progress = progress;
    },
    updateAssetStat(state, assetData) {
      state.part.assets[assetData['uid']] = {
        ...state.part.assets[assetData['uid']],
        ...assetData['asset_data'],
      };
    },
    removeProcessChain(state, uid) {
      delete state.part.process_chains[uid];
    },
    updateProcessChainStatus(state, data) {
      if (state.part.process_chains[data['process_chain_id']] != undefined) {
        state.part.process_chains[data['process_chain_id']].outdated = data.outdated;
        state.part.process_chains[data['process_chain_id']].status = data.status;
        state.part.process_chains[data['process_chain_id']].active_task = data.active_task;
      }
    },
    updateProcessChain(state, data) {
      if (data.process_chains != undefined) {
        Object.keys(data.process_chains).forEach(key => {
          state.part.process_chains[key] = { ...data.process_chains[key] };
        });
      }
    },
    updateSingleProcessChain(state, data) {
      if (data.process_chain_id != undefined) {
        state.part.process_chains[data.process_chain_id] = { ...data };
      }
    },
    updateProcessChainFromTemplate(state, processChains) {
      state.part.process_chains = processChains;
    },
    updateFilterLibrary(state, data) {
      if (data.hasOwnProperty('combo_categories')) {
        for (let [key, value] of Object.entries(data['combo_categories'])) {
          state.partLibraryFilters.combo_categories[key] = value;
          if (
            state.partLibraryFilters.combo_categories[key] == undefined ||
            state.partLibraryFilters.combo_categories[key].length == 0
          ) {
            delete state.partLibraryFilters.combo_categories[key];
          }
        }
      }
      if (data.hasOwnProperty('text_categories')) {
        for (let [key, value] of Object.entries(data['text_categories'])) {
          state.partLibraryFilters.text_categories[key] = value;
        }
      }
      state.partLibraryFilters = { ...state.partLibraryFilters, ...data };
    },
    resetFilter(state, data) {
      for (let [key, value] of Object.entries(data)) {
        if (key === 'combo_categories') {
          delete state.partLibraryFilters.combo_categories[value];
        }
        if (key === 'text_categories') {
          delete state.partLibraryFilters.text_categories[value];
        } else {
          delete state.partLibraryFilters[value];
        }
      }
    },
    resetAllLibraryFilters(state) {
      state.partLibraryFilters = { combo_categories: {}, text_categories: {} };
    },
    setPossiblePartLibraryFilters(state, filters) {
      state.possiblePartLibraryFilters = filters;
    },
    enablePartLibraryFilters(state, filters) {
      state.possiblePartLibraryFilters.enabled = { ...state.possiblePartLibraryFilters.enabled, ...filters };
      Object.keys(filters).forEach(key => {
        delete state.possiblePartLibraryFilters.disabled[key];
      });
    },
    disablePartLibraryFilter(state, filter) {
      state.possiblePartLibraryFilters.disabled = {
        ...state.possiblePartLibraryFilters.disabled,
        ...filter,
      };
      let key = Object.keys(filter)[0];
      delete state.possiblePartLibraryFilters.enabled[key];
    },
    updateFilterDashboard(state, data) {
      if (data.hasOwnProperty('combo_categories')) {
        for (let [key, value] of Object.entries(data['combo_categories'])) {
          state.dashboardFilters.combo_categories[key] = value;
          if (
            state.dashboardFilters.combo_categories[key] == undefined ||
            state.dashboardFilters.combo_categories[key].length == 0
          ) {
            delete state.dashboardFilters.combo_categories[key];
          }
        }
        delete data['combo_categories'];
      }
      if (data.hasOwnProperty('text_categories')) {
        for (let [key, value] of Object.entries(data['text_categories'])) {
          state.dashboardFilters.text_categories[key] = value;
        }
        delete data['text_categories'];
      }
      state.dashboardFilters = { ...state.dashboardFilters, ...data };
    },
    resetAllDashboardFilters(state) {
      state.dashboardFilters = {
        part_stat: 'validated',
        query_type: 'organization',
        combo_categories: {},
        text_categories: {},
      };
    },
    updateLibraryUserSettings(state, data) {
      state.user.user_settings.part_library_columns = data;
    },
    updateMaterialUserSettings(state, data) {
      state.user.user_settings.material_library_columns = data;
    },
    updateMachineUserSettings(state, data) {
      state.user.user_settings.machine_library_columns = data;
    },
    updatePartScaling(state, data) {
      // Update when a part is scales from mm to inch and back
      state.part.bb_x = data['bb_x'];
      state.part.bb_y = data['bb_y'];
      state.part.bb_z = data['bb_z'];
      state.part.min_bb_x = data['min_bb_x'];
      state.part.min_bb_y = data['min_bb_y'];
      state.part.min_bb_z = data['min_bb_z'];
      state.part.part_vol = data['part_vol'];
      state.part.current_part_mass = data['current_part_mass'];
      state.part.part_srf_area = data['part_srf_area'];
      state.part.visualization_file = data['visualization_file'];
      if (data['process_chains'] && state.part.process_chains) {
        for (let process_chain_id in data['process_chains']) {
          if (state.part.process_chains.hasOwnProperty(process_chain_id)) {
            state.part.process_chains[process_chain_id].part_mass = data['process_chains'][process_chain_id].part_mass;
          }
        }
      }
      // make sure to reload the canvas with the new visualization file
      state.reloadCanvas = true;
    },
    setCurrentMat(state, data) {
      state.part.current_mat = data;
    },
    setCurrentMatAnalysis(state, data) {
      state.part.current_mat_analysis = data;
    },
    updateExternalMarketPrice(state, data) {
      state.part.market_prices_api = data;
    },
    updateAllMachines(state, data) {
      state.allMachines = data;
    },
    updateFilteredMachines(state, data) {
      state.filteredMachines = data;
    },
    filterMachinesOnProcess(state, prc_uid) {
      let filtered = Object.fromEntries(
        Object.entries(state.allMachines).filter(([, value]) => value.prc_uid == prc_uid)
      );
      this.commit('updateFilteredMachines', filtered);
    },
    changeDisplayInfo(state, content) {
      state.part.displayInfo = content;
    },
    changeInvestigationDetails(state, data) {
      if (
        state.investigationDetails.content == data.content &&
        data != '' &&
        state.investigationDetails.uid == data.uid
      ) {
        state.investigationDetails.uid = data.uid;
        state.investigationDetails.content = '';
      } else {
        state.investigationDetails = data;
      }
    },
    changeProcessName(state, data) {
      state.part.process_chains[data.uid].name = data.name;
    },
    changeProcessChainUids(state, data) {
      for (let [key, value] of Object.entries(data)) {
        state.part[key] = value;
      }
    },
    updateFeasibilityChecks(state, data) {
      let processChainsExist = Object.keys(state.part.process_chains).length != 0;
      let selected_proc_chain = state.part.process_chains[data.uid];
      if (
        processChainsExist &&
        selected_proc_chain?.feasibility != undefined &&
        selected_proc_chain?.tech == 'additive_manufacturing'
      ) {
        selected_proc_chain.feasibility.support_occluded.min.chk = data.occludedSupports;
        selected_proc_chain.feasibility.fits_mac.max.chk = data.fitsMachine;
      }
    },
    updateTemplatesAndProfiles(state, data) {
      state.reloadTemplatesAndProfiles = data;
    },
    pdfRequested(state, data) {
      state.part.pdf_requested = data;
    },
    addAdditionalSaving(state, data) {
      let keys = Object.keys(data);
      if (keys.includes('cost_pp')) {
        let primary_process_chain_id = state.part.primary_process_chain_id;
        let lot_size = state.part.process_chains[primary_process_chain_id].lot_size;
        data['custom_cost_saved_pl'] = data['cost_pp'] * lot_size;
      } else {
        data['custom_cost_saved_pl'] = data['cost_pl'];
      }
      state.part.additional_savings.push(data);
    },
    removeAdditionalSaving(state, data) {
      let keys = Object.keys(state.part.additional_savings);
      keys.forEach(key => {
        if (state.part.additional_savings[key].uid == data) {
          delete state.part.additional_savings[key];
        }
      });
    },

    addDrawingAnalsysisResults(state, data) {
      state.part.drawing_analysis = data;
    },

    setOrganization(state, data) {
      state.organization = data;
    },

    setProductFruitsInitialized(state) {
      state.productFruitsInitialized = true;
    },

    sendTriggerRFQ(state) {
      state.triggerRFQ += 1;
    },
    setAvailableRoles(state, data) {
      state.roles = data;
    },
    setProcessChainSorting(state, data) {
      state.part.sorting_name = data.sorting_name;
      state.part.sorting_ascending = data.sorting_ascending;
    },
  },
  getters: {
    ///// PART (CAD) RELATED /////////////////////////////////////////////
    cadComputationRunning: state => {
      let boolRunning = false;
      // cad_stat does not exist on empty mounting of partframe
      if (state.part.cad_stat == undefined || state.part.cad_stat.active == null) return boolRunning;

      if (state.part.cad_stat.active !== 'pending') {
        boolRunning = true;
      }

      return boolRunning;
    },
    cadStatus: state => {
      /* A user friendly representation of the current status of the cad conversion. */
      let userString = '';
      if (state.part.cad_stat !== undefined) {
        let activeCadStat = state.part.cad_stat?.active;
        let previousCadStat = state.part.cad_stat?.previous;
        if (activeCadStat === 'pending') {
          userString = state.part.cad_stat?.choices[previousCadStat].finished_message;
        } else if (activeCadStat === null) {
          return userString;
        } else {
          userString = state.part.cad_stat?.choices[activeCadStat].active_message;
        }
      }
      return userString;
    },
    fileConversionFinished: state => {
      let boolFinished = false;
      if (state.part.cad_stat != undefined) {
        boolFinished = state.part.cad_stat.choices['file_conversion']?.finished;
        // if (boolFinished === undefined) return true;
      }
      return boolFinished;
    },
    fileGeometricPropertiesFinished: state => {
      let boolFinished = false;
      if (state.part.cad_stat != undefined) {
        boolFinished = state.part.cad_stat.choices['compute_geometric_properties']?.finished;
        if (boolFinished == undefined) boolFinished = true;
      }
      return boolFinished;
    },
    /////////// PROCESS CHAIN RELATED /////////////////////////////////////////
    processChains: state => {
      return state.part.process_chains;
    },
    processChainsInitializationFinished: state => {
      return checkAllProcessChainStatus(state.part.process_chains, 'initialized');
    },

    processChainsAnalysisFinished: state => {
      return checkAllProcessChainStatus(state.part.process_chains, 'finished');
    },

    processChainsAnalysisRunning: state => {
      return checkAnyProcessChainStatus(state.part.process_chains, 'running');
    },

    //////////ASSET RELATED ///////////////////////////////////////////////////////
    assetAmount: state => {
      let assetAmount = 0;
      if (state.part.assets != undefined) {
        for (const [key, value] of Object.entries(state.part.assets)) {
          assetAmount++;
        }
      }
      return assetAmount;
    },
    // assetAnalysisFinished: (state, getters) => {
    //   // check if whole asset analysis of all assets is finished
    //   let boolFinished = true;
    //   let breaker = false;
    //   if (getters.assetAmount === 0) return boolFinished;
    //   if (state.part.assets !== undefined) {
    //     if (Object.keys(state.part.assets)[0] !== undefined) {
    //       for (const [key, value] of Object.entries(state.part.assets)) {
    //         if (breaker) break;

    //         try {
    //           for (const [key, value] of Object.entries(value.asset_stat.choices)) {
    //             if (value.name === "Upload" && !value.finished) {
    //               boolFinished = false;
    //               breaker = true;
    //               break;
    //             }
    //           }
    //         } catch (error) {
    //           console.log(
    //             "Something strange occured while uploading an asset. Setting asset analysis to finished in order to keep the upload field running."
    //           );
    //           boolFinished = true;
    //           breaker = true;
    //           break;
    //         }
    //       }
    //     }
    //   }
    //   return boolFinished;
    // },
    lockedForUser: state => {
      if (state.part.part_id === '' || state.part.part_id === '0') {
        return false;
      } else {
        return state.part.creator !== state.user.username && state.user.user_role === 'poweruser';
      }
    },
    managerOrPartByUser: state => {
      return state.part.creator === state.user.username || state.user.user_role === 'manager';
    },
    // processChainComputationRunning: (state, getters) => {
    //   let boolRunning = false;
    //   let chains = getters.processChains;
    //   let keys = Object.keys(chains);
    //   if (keys.length == undefined) return boolRunning;
    //   keys.forEach((key) => {
    //     console.log(chains[key].process_chain_stat);
    //     if (chains[key].process_chain_stat.active != "pending") {
    //       boolRunning = true;
    //     }
    //   });
    //   //  Check missing:  if (amChain.process_chain_stat == undefined || amChain.process_chain_stat.active == null) return boolRunning;
    //   return boolRunning;
    // },
    // computationRunning: (state, getters) => {
    //   /* A getter that returns a bool that indicates weather some kind of computation is currently running.
    //    This can be used to provide the user from making inputs during running computations. */

    //   // computation running can mean cad and / or process chain is processed
    //   return getters.cadComputationRunning || getters.processChainComputationRunning;
    // },
    // materialChangeEnabled: (state, getters) => {
    //   return !getters.computationRunning && getters.fileConversionFinished;
    // },
    // analysisFinished: (state, getters) => {
    //   // check if whole analysis (of all process chains) is finished
    //   let finished = [];
    //   let boolFinished = true;

    //   let chains = getters.processChains;
    //   let keys = Object.keys(chains);
    //   if (keys.length == undefined) return boolFinished;

    //   keys.forEach((key) => {
    //     let choices = chains[key].process_chain_stat.choices;
    //     for (const [key, value] of Object.entries(choices)) {
    //       console.log("calc");
    //       if (!value.finished) {
    //         // orientation_cost_analysis can be deselected in the analysis settings
    //         if (key === "orientation_cost_analysis") {
    //           if (state.part.opt_style === "fixed") {
    //           } else {
    //             if (!value.outdated) {
    //               finished.push(false);
    //             }
    //           }
    //         } else {
    //           finished.push(false);
    //         }
    //       } else {
    //         finished.push(true);
    //       }
    //     }
    //   });
    //   console.log(finished);
    // let amChain = getters.amProcessChain;
    // if (amChain == undefined) return false;
    // for (const [key, value] of Object.entries(amChain.process_chain_stat.choices)) {
    //   if (!value.finished) {
    //     // orientation_cost_analysis can be deselected in the analysis settings
    //     if (key === "orientation_cost_analysis") {
    //       if (state.part.opt_style === "fixed") {
    //       } else {
    //         if (!value.outdated) {
    //           boolFinished = false;
    //         }
    //       }
    //     } else {
    //       boolFinished = false;
    //     }
    //   }
    // }
    //   return boolFinished;
    // },
    // processChainStatus: (state, getters) => {
    //   /* A user friendly representation of the current status of the process chain analysis. */
    //   if (getters.resultsOutdated) {
    //     return "Results Outdated.";
    //   }
    //   let userString = "";
    //   // try {
    //   //   if (state.part.process_chains != undefined) {
    //   //     let amChain = getters.amProcessChain;
    //   //     if (amChain == undefined) return userString;
    //   //     let activeProcessChainStat = amChain.process_chain_stat.active;
    //   //     let previousProcessChainStat = amChain.process_chain_stat.previous;
    //   //     if (activeProcessChainStat == null || previousProcessChainStat == null) {
    //   //       return userString;
    //   //     }
    //   //     if (amChain.process_chain_stat.choices[previousProcessChainStat] == undefined) {
    //   //       return userString;
    //   //     } else {
    //   //       if (activeProcessChainStat == "pending") {
    //   //         userString = amChain.process_chain_stat.choices[previousProcessChainStat].finished_message;
    //   //         if (getters.analysisFinished) {
    //   //           userString = "Analysis finished.";
    //   //         }
    //   //       } else {
    //   //         userString = amChain.process_chain_stat.choices[activeProcessChainStat].active_message;
    //   //       }
    //   //     }
    //   //   }
    //   // } catch (error) {
    //   //   console.log("An unexpected error occured while reading the processChainStatus in index.js: ", error);
    //   //   return userString;
    //   // }
    //   return userString;
    // },
    // orientationOptimizationFinished: (state, getters) => {
    //   // let amChain = getters.amProcessChain;
    //   // if (amChain == undefined) return false;
    //   // if (amChain.cost == undefined) return false;
    //   // return amChain.process_chain_stat.choices["orientation_cost_analysis"].finished;
    //   return true;
    // },
    // resultsOutdated: (state, getters) => {
    //   /* Returns a bool for weather some results are outdated because of user inputs. */
    //   // if (state.part.process_chains != undefined) {
    //   //   let amChain = getters.amProcessChain;
    //   //   if (amChain == undefined) return false;
    //   //   if (getters.analysisFinished) {
    //   //     for (const [key, value] of Object.entries(amChain.process_chain_stat.choices)) {
    //   //       if (value.outdated == true) return true;
    //   //     }
    //   //   }
    //   // }
    //   return false;
    // },
    // analysisInputsComplete: (state, getters) => {
    //   // if (state.part.basename !== "" && getters.amProcessChain?.mat_id !== "" && getters.amProcessChain?.mat_id != null) {
    //   //   return true;
    //   // } else {
    //   //   return false;
    //   // }
    //   return true;
    // },
  },
  // actions are like mutations, with the only difference that they can be asynchronous
  actions: {
    async initializeStore({ state }) {
      state.isAuthenticated = false;
      if (localStorage.getItem('user')) {
        state.user = JSON.parse(localStorage.getItem('user'));
      }
    },

    async fetchOrganizationGoal({ commit, rootState }) {
      const response = await rootState.application.axiosInstance.get('api/v1/organization-goal/');
      const goals = response.data;
      const goalsData = goals.map(goal => {
        const year = new Date(goal.period_start).getFullYear();
        return {
          id: goal.id,
          uid: goal.uid,
          name: goal.name,
          title: getDayAndMonthFromDate(goal.period_start, goal.period_end),
          periodStart: goal.period_start,
          periodEnd: goal.period_end,
          co2Saved: goal.co2_saved,
          leadTimeSaved: goal.leadtime_saved,
          costSaved: goal.cost_saved,
          partsValidated: goal.parts_validated,
          organization: goal.organization,
          comboCategoryFilterValue: goal.combo_category_filter_value,
          year,
        };
      });
      commit('setorganizationGoal', goalsData);
      return response;
    },

    async addorganizationGoal({ rootState, dispatch }, goalData) {
      await rootState.application.axiosInstance.post('api/v1/organization-goal/', goalData);
      await dispatch('fetchOrganizationGoal');
    },

    async deleteorganizationGoal({ rootState, dispatch }, goalId) {
      await rootState.application.axiosInstance.delete(`api/v1/organization-goal/${goalId}/`);
      await dispatch('fetchOrganizationGoal');
    },

    async updateorganizationGoal({ rootState, dispatch }, goalData) {
      await rootState.application.axiosInstance.put(`api/v1/organization-goal/${goalData.uid}/`, goalData.goal);
      await dispatch('fetchOrganizationGoal');
    },

    async fetchOrganizationGoalDashboardDetails({ rootState, commit }) {
      const response = await rootState.application.axiosInstance.get('api/v1/dashboard/organization-goal-dashboard/');
      if (!response.data || !Object.keys(response.data).length) {
        commit('setorganizationGoalDashboardDetails', []);
        return;
      }
      const outputData = [];

      const details = response.data;

      for (const goalDetail in details) {
        const goal = details[goalDetail];

        const [startYear, endYear] = goalDetail.split(' - ');
        const goalToday = calculatePercentageOfGoal(new Date(startYear), new Date(endYear));

        outputData.push({
          name: goal.name,
          partsValidatedAchieved: goal.parts_validated_achieved,
          partsValidatedGoal: goal.parts_validated_goal,
          partsValidatedPercent: Math.floor(goal.parts_validated_percent * 100),
          costAchieved: goal.cost_achieved,
          costGoal: goal.cost_goal,
          costPercent: Math.floor(goal.cost_percent * 100),
          leadtimeAchieved: goal.leadtime_achieved,
          leadtimeGoal: goal.leadtime_goal,
          leadtimePercent: Math.floor(goal.leadtime_percent * 100),
          co2Achieved: goal.co2_achieved,
          co2Goal: goal.co2_goal,
          co2Percent: Math.floor(goal.co2_percent * 100),
          goalPercentToday: goalToday,
          title: getDayAndMonthFromDate(startYear, endYear, 3),
          partsValidatedBackgroundColor: getGoalBackgroundColor(
            goalToday,
            Math.floor(goal.parts_validated_percent * 100)
          ),
          costBackgroundColor: getGoalBackgroundColor(goalToday, Math.floor(goal.cost_percent * 100)),
          leadtimeBackgroundColor: getGoalBackgroundColor(goalToday, Math.floor(goal.leadtime_percent * 100)),
          co2BackgroundColor: getGoalBackgroundColor(goalToday, Math.floor(goal.co2_percent * 100)),
        });
      }

      commit('setorganizationGoalDashboardDetails', outputData);
    },

    async fetchOrganization({ rootState, commit }) {
      const response = await rootState.application.axiosInstance.get('api/v1/organization-settings/');
      commit('setOrganization', response.data);
    },

    // Pipeline statuses
    async fetchPipelineStatuses({ commit, rootState }) {
      const response = await rootState.application.axiosInstance.get('/api/v1/pipeline-status-list/');
      const statuses = response.data;
      commit('setPipelineStatuses', statuses);
      return response;
    },

    async updatePipelineStatusOrder({ rootState, commit }, statusData) {
      const response = await rootState.application.axiosInstance.put('/api/v1/pipeline-status-list/', statusData);
      const statuses = response.data;
      commit('setPipelineStatuses', statuses);
    },

    async addPipelineStatus({ rootState, dispatch }, statusData) {
      await rootState.application.axiosInstance.post('api/v1/pipeline-status/', statusData);
      await dispatch('fetchPipelineStatuses');
    },

    async deletePipelineStatus({ rootState, dispatch }, statusUid) {
      await rootState.application.axiosInstance.delete(`api/v1/pipeline-status/${statusUid}`);
      await dispatch('fetchPipelineStatuses');
    },

    async updatePipelineStatus({ rootState, dispatch }, statusData) {
      await rootState.application.axiosInstance.put(`api/v1/pipeline-status/${statusData.uid}`, statusData);
      await dispatch('fetchPipelineStatuses');
    },

    // Part Library
    async fetchPartLibraryData({ rootState, commit }, formData) {
      const response = await rootState.application.axiosInstance.post('api/v1/part-library/', formData);
      commit('updatedPartLibraryData', response.data);
    },

    // Parts
    async setPartsData({ rootState, commit, dispatch }, partsEvent) {
      const parts = partsEvent.results ?? [];

      const newParts = parts.map(part => {
        const comboCategories = rootState.user.organization.combo_categories;
        const textCategories = rootState.user.organization.text_categories;

        const categories = combineCategories(
          { combo_categories: comboCategories, text_categories: textCategories },
          part.categories
        );

        return { ...part, categories };
      });

      function combineCategories(organizationCategories, partCategories) {
        let mergedCategories = { combo_categories: {}, text_categories: {} };

        // Combine combo_categories
        for (let key in organizationCategories.combo_categories) {
          let uid = key;
          if (partCategories.combo_categories && partCategories.combo_categories[uid]) {
            mergedCategories.combo_categories[uid] = {
              ...organizationCategories.combo_categories[uid],
              ...partCategories.combo_categories[uid],
            };
            mergedCategories.combo_categories[uid].uid = Object.keys(mergedCategories.combo_categories[uid].items).find(
              key => mergedCategories.combo_categories[uid].items[key] === mergedCategories.combo_categories[uid].value
            );
          }
        }

        // Combine text_categories
        for (let key in organizationCategories.text_categories) {
          let uid = key;
          if (partCategories.text_categories && partCategories.text_categories[uid]) {
            mergedCategories.text_categories[uid] = {
              ...organizationCategories.text_categories[uid],
              ...partCategories.text_categories[uid],
            };
          }
        }

        return mergedCategories;
      }

      commit('setParts', newParts);

      parts.map(part => {
        if (!part.thumbnail) {
          dispatch('fetchThumbnail', part.part_id);
        }
        // const uid = part.process_uid;
        // if (uid) {
        //   part['prc_acronym'] = rootState.user.organization.process_synonyms[uid].prc_acronym;
        //   part['prc_name'] = rootState.user.organization.process_synonyms[uid].prc_name;
        // }
      });
    },

    async fetchThumbnail({ commit, rootState }, partId) {
      const updateThumbnail = (partId, thumbnail) => {
        const updatedParts = rootState.parts.map(part => {
          if (part?.part_id === partId) {
            const updatedPart = { ...part, thumbnail };
            return updatedPart;
          }
          return part;
        });

        commit('setParts', updatedParts);
      };

      // Check if the thumbnail is already cached
      if (rootState.application.cachedThumbnails[partId]) {
        updateThumbnail(partId, rootState.application.cachedThumbnails[partId]);
        return;
      } else {
        updateThumbnail(partId, defaultImage);
      }

      if (partId) {
        try {
          const response = await rootState.application.axiosInstance.get(`api/v1/part-thumbnail/${partId}/`, {
            responseType: 'blob',
          });
          const fileReader = new FileReader();
          fileReader.readAsDataURL(response.data);
          fileReader.onload = e => {
            const thumbnail = e.target.result || defaultImage;

            // Cache the thumbnail in the store
            commit('application/cacheThumbnail', { part_id: partId, thumbnail }, { root: true });
            updateThumbnail(partId, thumbnail);
          };
        } catch (error) {
          console.log(error);
          updateThumbnail(partId, defaultImage);
        }
      }
    },

    async fetchBatches({ rootState, commit }) {
      await rootState.application.axiosInstance
        .get('/api/v1/batch-dropdown/')
        .then(response => commit('setBatches', response.data))
        .catch(error => {
          console.log(error);
        });
    },

    async fetchPricingOptionList({ commit, rootState }) {
      const response = await rootState.application.axiosInstance.get(`api/v1/pricing-config-list/`);
      response.data.sort((a, b) => a.name.localeCompare(b.name));
      commit('setPricingOptions', response.data);
    },

    async deletePricingOption({ rootState, dispatch }, pricingOptionUid) {
      await rootState.application.axiosInstance.delete(`api/v1/pricing-config/${pricingOptionUid}/`);
      await dispatch('fetchPricingOptionList');
    },

    async getProcessChainExpressionVariables({ commit, rootState }) {
      const response = await rootState.application.axiosInstance.get('api/v1/expression-variables/process-chain/');
      commit('setProcessChainExpressionVariables', response.data);
    },

    async fetchAnalysisProfileList({ commit, rootState }) {
      const response = await rootState.application.axiosInstance.get(`/api/v1/analysis-profile-list/`);
      commit('setAnalysisProfiles', response.data);
    },

    async fetchAnalysisTemplateList({ commit, rootState }) {
      const response = await rootState.application.axiosInstance.get(`/api/v1/process-chain-template-list/`);
      commit('setAnalysisTemplates', response.data);
    },

    async fetchAnalysisTemplatesAndProfiles({ commit, rootState, dispatch }) {
      // await rootState.application.getUser(
      //   rootState.application.keycloak,
      //   rootState.application.axiosInstance,
      //   rootState
      // );
      await dispatch('fetchAnalysisTemplateList');
      await dispatch('fetchAnalysisProfileList');
      commit('setAnalysisTemplatesAndProfiles', { ...rootState.analysisTemplates, ...rootState.analysisProfiles });
    },
  },

  modules: {
    canvas: canvasModule,
    application: applicationModule,
    supplier: supplierModule,
    prp: prpModule,
    calibration: calibrationModule,
  },
});

export default store;

export const websocket = function () {
  return this.$keycloak.token;
};
